* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
/* navbar section */
.AJ img {
  height: 5rem;
  width: 8rem;
  color: rgb(255, 255, 255);
}
.my-nav {
  height: 5rem;
  margin-bottom: -5rem;
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
}

.nav-menu {
  width: 91vw;
  display: flex;
  justify-content: space-between;
}
.nav-menu ul {
  display: flex;
  justify-content: center;
}
.hamburgerBars {
  z-index: 2;
  color: white;
  font-size: 1.5rem;
  display: none;
}
.nav-item {
  padding: 1rem;
}
.single_link {
  /* margin-top: 0.5rem; */
  list-style-type: none;
  font-size: 1rem;
  text-shadow: 1px 1px black;
}
.single_link a {
  text-decoration: none;
  color: rgb(255, 255, 255);
}
.single_link a:hover {
  text-decoration: none;
  color: rgb(255, 255, 255);
}
.nav_socials a {
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
}
.nav_socials a:hover {
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
}
/* end of navbar section  */

/* home section  */
.home_section {
  background: rgb(1, 58, 75);
  background: linear-gradient(
    153deg,
    rgba(1, 58, 75, 1) 0%,
    rgba(1, 54, 68, 1) 60%,
    rgba(18, 41, 47, 1) 100%
  );
  text-shadow: 2px 2px black;
}
/* .home .btn {
  box-shadow: 1px 1px 20px black !important;
} */
.home {
  height: 100vh;
  background-attachment: fixed;
  background-image: url("/images/aj.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  min-width: 100%;
}
.contact_section {
  background-color: #82320a;
}
.news_section {
  background-color: #0f0f0f;
}
.bio_section {
  background-color: #040e22;
}
.tour_section {
  background-color: #022441;
}
.contact {
  min-height: 100vh;
  background-attachment: fixed;
  background-image: url(/images/NANA\ AJ\ train\ of\ thoughts\ cover\ shoot2625\ Kopie.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.bio {
  min-height: 100vh;
  background-attachment: fixed;
  background-image: url(/images/Cover_option_01TRAIN.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.news {
  min-height: 100vh;
  background-attachment: fixed;
  background-image: url(/images/WhatsApp\ Image\ 2022-05-12\ at\ 4.29.17\ PM.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 50%;
}
.tour {
  min-height: 100vh;
  background-attachment: fixed;
  background-image: url(/images/gif2.gif);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
}

.main_sec {
  margin-top: 80vh;
}
.contact_sec {
  margin-top: 80vh;
}
.bio_sec {
  margin-top: 80vh;
}
.tour_sec {
  margin-top: 80vh;
}
.my_btn {
  overflow: visible;
  width: 15rem;
}

.newsletter-section {
  background-color: #010600;
}
.newsletter {
  min-height: 100vh;
  background: url(/images/photo_2022-05-11_19-27-19.jpg);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}
.newsletterform {
  margin-top: 60vh;
}
.email-input {
  height: 3.6rem;
  padding: 1rem;
  border-radius: 0;
}
.signup-btn {
  color: black;
  background-color: white;
  margin-left: 0.25rem;
  border: 0;
  height: 3.6rem;
  width: 5rem;
}
.signup-btn:hover {
  color: white;
  background-color: black;
  border: 1px solid white;
}
.email,
.fName,
.lName,
.message {
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.5rem;
  width: 80%;
}
.required::after {
  content: "*";
  padding-left: 0.2rem;
  color: red;
}
.submit-btn {
  color: black;
  background-color: white;
  margin-top: 1rem;
  height: 3.6rem;
  width: 6rem;
  border: 1px solid rgb(0, 0, 0);
}
.submit-btn:hover {
  color: white;
  background-color: black;
  border: 1px solid white;
}

.footer {
  min-height: 10vh;
  display: flex;
  align-items: center;
}
.footer a:hover {
  color: black;
}
.footer_socials a {
  color: rgb(0, 0, 0);
  font-size: large;
}
.bookings p {
  color: #82320a;
  text-decoration: underline;
  display: inline;
}
.bookings a {
  color: #82320a;
}
.bookings a:hover {
  color: #82320a;
}

.read-more {
  text-decoration: underline;
  color: black;
}
.read-more:hover {
  color: black;
}
.big {
  display: block;
}
.small {
  display: none;
}
@media (max-width: 900px) {
  .hamburgerBars {
    display: block;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .nav-menu {
    z-index: -1;
    position: absolute;
    left: -100rem;
    top: 0rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: 0.3s ease;
  }
  .active-menu {
    z-index: 2;
    background-color: black;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease;
  }
  .nav-menu ul {
    flex-direction: column;
  }
  /* .nav_socials {
    display: none;
  } */
  .nav-item {
    font-size: 1.5rem;
  }
  .single_link {
    text-align: center;
    padding-top: 1rem;
    font-size: 3rem;
  }
}
@media all and (max-width: 768px) {
  .main_sec {
    margin-top: 25vh;
  }
}

@media all and (max-width: 530px) {
  .main_sec {
    margin-top: 20vh;
  }
  .home {
    height: 75vh;
    background-attachment: scroll;
  }
  .contact {
    background-attachment: scroll;
    background-size: cover;
  }
  .bio {
    background-attachment: scroll;
    background-size: cover;
  }
  .news {
    background-attachment: scroll;
    background-size: cover;
  }
  .tour {
    background-attachment: scroll;
    background-size: cover;
  }
  .newsletter {
    background-attachment: scroll;
  }
}
@media all and (max-width: 1000px) {
  .home {
    background-attachment: scroll;
  }
  .contact {
    background-attachment: scroll;
    background-size: cover;
  }
  .bio {
    background-attachment: scroll;
    background-size: cover;
  }
  .news {
    background-attachment: scroll;
    background-size: cover;
  }
  .tour {
    background-attachment: scroll;
    background-size: cover;
  }
  .newsletter {
    background-attachment: scroll;
  }
}
@media all and (max-width: 767px) {
  .big {
    display: none;
  }
  .small {
    display: block;
  }
}
